import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import HCaptcha from "react-hcaptcha";
import exit_cross from "../../images/exit_cross.svg";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleSignup, handleGetUser } from "../../calls";
import Logo from "../../images/Logo.svg";
import growYourBusiness from "../../images/signup-login-grow-your-business.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./signup.css";

const axios = require("axios");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Signup = (props) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal2,
    setShowModal,
    showModal2,
    loader,
    setLoader,
    fullName,
    setFullName,
  } = props;

  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState([]);
  const [token_signup, setTokenSignUp] = useState(null);
  const [trySignUp, setTrySignUp] = useState(false);
  const [isPassHidden, setPassHidden] = useState(true);
  const [isConfirmPassHidden, setConfirmPassHidden] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [signupData, setSignupData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
  });

  const handleDataChange = (e) => {
    setSignupData({
      ...signupData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is logged in, navigate to "/"
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const captchaRefSignUp = useRef(null);

  const handlePassHidden = () => {
    setPassHidden(!isPassHidden);
  };

  const handleConfirmPassHidden = () => {
    setConfirmPassHidden(!isConfirmPassHidden);
  };

  const handleVerificationSuccess = (token, eKey) => {
    setTokenSignUp(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTrySignUp(true);

    if (signupData.password !== signupData.password_confirmation) {
      setSignUpError(["Passwords do not match"]);
      return;
    }

    handleSignup(signupData)
      .then((response) => {
        if (response.success) {
          console.log(response.data); // Successful response data
          localStorage.setItem("accessToken", response.data.accessToken);
          handleGetUser(localStorage.getItem("accessToken"))
            .then((response) => {
              console.log(response);
              const data = response.data;
              const fullname = `${data.first_name} ${data.last_name}`
              setFullName(fullname);
              setIsLoggedIn(true);
            })
        } else {
          console.error("Signup request failed");
          const error = response.error;
          if (error.response && error.response.status === 400) {
            const errorMessage = error.response.data.message;
            console.log(errorMessage);
            setSignUpError([errorMessage]);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle other errors as needed
      });
  };

  // Reset state after successful sign up
  const resetAfterSignUp = () => {
    setConfirmPassHidden(true);
    setPassHidden(true);
    setTrySignUp(false);
    setSignupData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
    });
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  // Reset state after sign up error
  const resetAfterSignUpError = () => {
    setTrySignUp(false);
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  const errorMsg = signUpError.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });

  return (
    <div>
      <div className="sign-up signup-circle">
        <div className="left-signup">
          <div className="site-logo">
            <a href="/" className="nav-link">
              <img src={Logo} alt="" className="logo-img" />
              <span className="site_title">KizReviews</span>
            </a>
          </div>
          <div className="hero-animation">
            <img src="/hero-animation.svg" className="hero-animation" alt="Hero Animation" />
          </div>
          <div className="grow-your-business">
            <img src={growYourBusiness} alt="Grow Your Business" />
          </div>
        </div>
        <div className="right-signup">
          <div className="right-signup-wrapper">
            <div className="d-flex justify-content-center mb-3 small-screen-logo">
              <a href="/" className="nav-link">
                <img src={Logo} alt="" className="logo-img" />
                <span className="site_title">KizReviews</span>
              </a>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="sign-up-form">
                <h6>Register</h6>
                <p className="signup-title">
                  Already have an account? <a href="/login">Login here</a>
                </p>
                {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
                <div className="mb-3">
                  <div className="mb-1">Full name</div>
                  <div className="d-flex">
                    <input
                      required
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                      value={signupData.first_name}
                      onChange={handleDataChange}
                    />
                    <input
                      required
                      name="last_name"
                      className="form-control ml-3"
                      placeholder="Last Name"
                      value={signupData.last_name}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="mb-1">Email</div>
                  <div className="d-flex">
                    <input
                      required
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={signupData.email}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="mb-1">Phone Number (optional)</div>
                  <div className="d-flex">
                    <input
                      name="phone_number"
                      className="form-control"
                      placeholder="Phone Number"
                      value={signupData.phone_number}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-1">Password</div>
                  <div className="password_field">
                    <input
                      required
                      type={isPassHidden ? "password" : "text"}
                      name="password"
                      className="form-control mb-2"
                      placeholder="Create Password"
                      value={signupData.password}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={() => setPassHidden(!isPassHidden)}
                    ></i>
                  </div>
                  <div className="password_field">
                    <input
                      required
                      type={isConfirmPassHidden ? "password" : "text"}
                      name="password_confirmation"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={signupData.password_confirmation}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isConfirmPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={() => setConfirmPassHidden(!isConfirmPassHidden)}
                    ></i>
                  </div>
                </div>
                <div className="form-check">
                  <input
                    required
                    type="checkbox"
                    className="form-check-input"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                  />
                  <label className="form-check-label" htmlFor="agreeToTerms">
                    I agree to <a href="#">terms of conditions</a> and{" "}
                    <a href="#">privacy policy</a>
                  </label>
                </div>
                <div>
                  <button className="submit-btn" type="submit">
                    Register
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

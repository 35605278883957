import { BrowserRouter, Routes, Route, useLocation, useNavigate, Router } from "react-router-dom";
import { useState,useEffect, React } from "react";
import './css/bootstrap.css';
import './css/style.css';
import './css/responsive.css';
import Home from './pages/home/home';
import Signup from "./pages/signup/signup";
import Login from "./pages/login/login";
import Forget from "./pages/forget/forget";
import Account from "./pages/account/account";
import Reset from "./pages/reset/reset";
import Contact from './pages/contact/contact';
import Navbar from "./pages/navbar/navbar";
import Footer from "./pages/footer/footer";
import Orders from "./pages/orders/orders";
import Conditions from "./pages/conditions/conditions";
import Success from './pages/orderSuccess/success';
import Failed from './pages/orderFailed/failed';
import FollowOrders from './pages/followOrders/order'

function App() {
  let [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("accessToken", "")));
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [fullName, setFullName] = useState(localStorage.getItem("fullName", ""));
  const [showAccountModal, setshowAccountModal] = useState(false);
  const [balance, setBalance] = useState(localStorage.getItem("balance"));
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [loader, setLoader] = useState(true);

  return (
    
    <div className="App">
      <BrowserRouter>
       <Navbar
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName = {fullName}
          setFullName = {setFullName}
        />
        <Routes>
          <Route path="/" element={<Home
            isLoggedIn={isLoggedIn}
            fullName = {fullName}
            setFullName = {setFullName}
          />} />
          <Route path="/sign-up" element={<Signup 
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName={fullName}
          setFullName={setFullName}
          />} />
          <Route path="/login" element={<Login 
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName={fullName}
          setFullName={setFullName}
          loader={loader}
          setLoader = {setLoader}
          />} />
          <Route path="/forget-password" element={<Forget
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName={fullName}
          setFullName={setFullName}
          />} />
          {isLoggedIn && (
          <Route path="/account" element={<Account
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName={fullName}
          setFullName={setFullName}
          />} />
          )}
          <Route path="/contact" element={<Contact 
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          />} />
          {isLoggedIn && ( <Route path="/mes-commandes" element={<Orders/>} />)}
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/reset-password/:token"  element={<Reset 
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          fullName={fullName}
          setFullName={setFullName}
          />} />
          <Route path="/order/:orderid" element={<FollowOrders/>} />

          <Route path="/order-failed/" element={<Failed
            isLoggedIn={isLoggedIn}
          />} />
          <Route path="/order-success/" element={<Success/>} />
        </Routes>

        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

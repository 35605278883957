
import user from '../../images/user.png';

import { useLocation, useParams, useNavigate, useSearchParams } from "react-router-dom";

import './navbar.css';
import Logo from '../../images/Logo.svg';
import { Dropdown } from "react-bootstrap";
import { loginData } from "./../login/login";
import { useState, useEffect } from 'react';
import {handleGetUser} from "../../calls";

const Navbar = (props) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    fullName,
    setFullName,
  } = props;
  
  useEffect(() => {
  if (isLoggedIn) {
    getUser();
  }
}, [isLoggedIn]); 

  const getUser = () => {
    handleGetUser(localStorage.getItem("accessToken"))
      .then((response) => {
        const data = response.data;
        const fullName = `${data.first_name} ${data.last_name}`;
        setFullName(fullName);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  let navigate = useNavigate();

  const handleLogout = () => {
    console.log(localStorage.getItem('accessToken'));
    localStorage.removeItem('accessToken');
    console.log(localStorage);
    setIsLoggedIn(false);
    console.log('logged_out');
    navigate('/');
  };
  
  const [collapse, toggleCollapse] = useState(true);

  const handleNavToggle = () => {
    toggleCollapse(!collapse);
  };

  const [hideHeader, setHiderHeader] = useState(false);
  let location = useLocation();

  useEffect(() => {
    const hide = location.pathname !== '/sign-up' && location.pathname !== '/login';
    setHiderHeader(hide);
  }, [location]);

  return hideHeader && (
    <>
      <header className="header_section position-relative mb-md-0">
        <nav className="navbar py-4 navbar-expand-md custom_nav-container">
          <div className="w-100 px-lg-3 d-flex align-items-center justify-content-between">
            <div className="box">
              <div className="img-box">
                <a href="/" className='d-flex p-0 align-items-center'>
                  <img src={Logo} alt="" className='logo-img' />
                  <span className="site_title">KizReviews</span>
                </a>
              </div>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => handleNavToggle()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22">
                <g id="bars-line" transform="translate(-3 -7)">
                  <path id="Path_12" data-name="Path 12" d="M32,29H4a1,1,0,0,1,0-2H32a1,1,0,0,1,0,2Z"/>
                  <path id="Path_13" data-name="Path 13" d="M32,19H4a1,1,0,0,1,0-2H32a1,1,0,0,1,0,2Z"/>
                  <path id="Path_14" data-name="Path 14" d="M32,9H4A1,1,0,0,1,4,7H32a1,1,0,0,1,0,2Z"/>
                </g>
              </svg>
            </button>
            <div className={`navbar-collapse show collapse ${collapse ? 'collapsed' : ''}`} id="navbarSupportedContent">
              <div className="d-flex mx-auto flex-column flex-lg-row align-items-center nav_links">
                <ul className="navbar-nav">
                  <li className={`nav-item m-0 ${location.pathname == '/' ? 'nav-link-active' : ''}`}>
                    <a className="nav-link p-0" href="/">
                      Home
                    </a>
                  </li>
                  {isLoggedIn && (
                    <li className={`nav-item m-0 ${location.pathname == '/mes-commandes' ? 'nav-link-active' : ''}`}>
                      <a className="nav-link p-0" href="/mes-commandes">
                        Mes commandes
                      </a>
                    </li>
                  )}
                  <li className={`nav-item m-0 ${location.pathname == '/contact' ? 'nav-link-active' : ''}`}>
                    <a className="nav-link p-0" href="/contact">
                      Contact
                    </a>
                  </li>
                  {!isLoggedIn ? (
                    <li className={`nav-item m-0 ${location.pathname == '/login' ? 'nav-link-active' : ''}`}>
                      <a className="nav-link p-0" href="/login">
                        Se Connecter
                      </a>
                    </li>
                  ) : (
                    <li className='nav-item m-0'>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="dropdown-basic auth-dropdown">
                          <span>{fullName}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu p-2 px-3">
                          <ul className='p-0'>
                            <li>
                              <a href='/account' className='btn btn p-0 mb-1'>
                                My Account
                              </a>
                            </li>
                            <li>
                              <button className='btn btn p-0' onClick={() => { handleLogout() }}>
                                Se déconnecter
                              </button>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;


import envelopeWhite from '../../images/envelope-white.png';
import telephone from '../../images/call.png';
import Stroke from '../../images/Stroke.svg';

import { handleContactGuest,handleContactClient } from '../../calls';
import './contact.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Contact = (props) => {
//   const navigate = useNavigate();

//   const location = useLocation();
//   const { pathname } = location;
//   const { t } = useTranslation();
  const {
    isLoggedIn,
    setIsloggedIn,
  } = props;
  const [contactError, setContactError] = useState([]);
  const [contactSuccess, setContactSuccess] = useState([]);
  const [ContactData, setContactData] = useState({
    email: "",
    name:"",
    subject: "",
    message: "",
  });
  const handleDataChange = (e) => {
  setContactData({
    ...ContactData,
    [e.target.name]: e.target.value,
  });
  };
  const handleSubmit = (e) => {
  e.preventDefault();

  const contactFunction = isLoggedIn ? handleContactClient : handleContactGuest;

  contactFunction(ContactData)
    .then((response) => {
      console.log(response);
      if (response.success) {
        setContactSuccess([response.data.message]);
        setContactError([]);
      } else {
        handleContactError(response.error);
      }
    })
    .catch(handleContactError);
};
    

  const handleContactError = (error) => {
  const errorMessage = error?.response?.status === 400 ? error.response.data.message : "An error occurred during update";
    setContactError([errorMessage]);
    setContactSuccess([]);
  };
  

  const errorMsg = contactError.map((item, index) => (
    <h5 key={index} className="error_msg">
      {item}
    </h5>
  ));
  const successMsg = contactSuccess.map((item, index) => {
    return (
      <h5 key={index} className="success_msg">
        {item}
      </h5>
    );
  });

  return (
    <div className='bg-circle contact-page'>
      <div className='contact container pt-5'>
        <div className='row'>
          <div className='col-md-8 col-lg-7 col-xl-5 left-contact mx-auto'>
            <div className='mobile-contact-img mb-3'>
              <img src='./mobile-contact.png' className='w-100'></img>
            </div>
            <h2 className='contact-title'>
              We're ready and waiting for your questions
            </h2>
            <div className='stroke-bg'>
              <img src={Stroke} alt="" className='w-50' />
            </div>
            <p className='mt-4'>
              We want to hear from you. Let us know how can we help you?
            </p>
            {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
            {successMsg && <div className="mb-3 successm">{successMsg}</div>}
            <div className='mt-5 mb-5 pb-5'>
              <form onSubmit={handleSubmit}>
                {!isLoggedIn && (
                <div className="mb-3">
                  <div className="mb-1">Name</div>
                  <div className="d-flex">
                    <input
                      required
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      value={ContactData.name}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                )}
                {!isLoggedIn && (
                <div className="mb-3">
                  <div className="mb-1">Email</div>
                  <div className="d-flex">
                    <input
                      required
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={ContactData.email}
                      onChange={handleDataChange}
                    />
                  </div>
                  
                </div>
                )}
                <div className="mb-3">
                  <div className="mb-1">Subject</div>
                  <div className="d-flex">
                    <input
                      required
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      value={ContactData.subject}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-1">Message</div>
                  <div className="d-flex">
                    <textarea
                      required
                      name="message"
                      className="form-control"
                      placeholder='Enter your message here'
                      rows={7}
                      value={ContactData.message}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>

                <div>
                  <button className="submit-btn" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='col-7 right-contact'>
            <img 
              src='./contact-image.png'
              className='w-100 pl-5 ml-5'
            />
          </div>
        </div>
      </div>
    </div>  
  );
};

export default Contact;

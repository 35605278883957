import {useLocation} from "react-router-dom";
import { useState, useEffect} from "react";
import './footer.css'
import WhiteLogo from '../../images/logo-white.png';

const Footer = () => {

  const [hideFooter, setHiderFooter] = useState(false);


  let location = useLocation()

  useEffect(() => {
      const hide = location.pathname != '/sign-up' && location.pathname != '/login' 
      setHiderFooter(hide);
    },[location])
  
  return (
    hideFooter && <footer className='footer-main'>
      <div className='upper-footer'>
        <div className='container'>
          <div className='row w-100'>
            <div className='col-md-4 mb-5'>
              <img src={WhiteLogo} className='img-fluid'></img>

              <p className='footer-description'>
                KizReviews is a digital marketing agency that is helping businesses to solidify their online reputation with positive reviews
              </p>

              <ul className='d-flex justify-content-start pl-0 footer-social'>
                <li>
                  <a href='#'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_420_6424)">
                        <path d="M12.0042 5.83801C8.60119 5.83801 5.84619 8.59601 5.84619 11.996C5.84619 15.399 8.60419 18.154 12.0042 18.154C15.4072 18.154 18.1622 15.396 18.1622 11.996C18.1622 8.59301 15.4042 5.83801 12.0042 5.83801ZM12.0042 15.993C9.79519 15.993 8.00719 14.204 8.00719 11.996C8.00719 9.78801 9.79619 7.99901 12.0042 7.99901C14.2122 7.99901 16.0012 9.78801 16.0012 11.996C16.0022 14.204 14.2132 15.993 12.0042 15.993Z" fill="white"/>
                        <path d="M16.9482 0.075997C14.7402 -0.027003 9.27123 -0.022003 7.06123 0.075997C5.11923 0.166997 3.40623 0.635997 2.02523 2.017C-0.282773 4.325 0.0122273 7.435 0.0122273 11.996C0.0122273 16.664 -0.247773 19.702 2.02523 21.975C4.34223 24.291 7.49723 23.988 12.0042 23.988C16.6282 23.988 18.2242 23.991 19.8592 23.358C22.0822 22.495 23.7602 20.508 23.9242 16.939C24.0282 14.73 24.0222 9.262 23.9242 7.052C23.7262 2.839 21.4652 0.283997 16.9482 0.075997ZM20.4432 20.448C18.9302 21.961 16.8312 21.826 11.9752 21.826C6.97523 21.826 4.97023 21.9 3.50723 20.433C1.82223 18.756 2.12723 16.063 2.12723 11.98C2.12723 6.455 1.56023 2.476 7.10523 2.192C8.37923 2.147 8.75423 2.132 11.9612 2.132L12.0062 2.162C17.3352 2.162 21.5162 1.604 21.7672 7.148C21.8242 8.413 21.8372 8.793 21.8372 11.995C21.8362 16.937 21.9302 18.954 20.4432 20.448Z" fill="white"/>
                        <path d="M18.4058 7.03401C19.2005 7.03401 19.8448 6.38974 19.8448 5.59501C19.8448 4.80027 19.2005 4.15601 18.4058 4.15601C17.6111 4.15601 16.9668 4.80027 16.9668 5.59501C16.9668 6.38974 17.6111 7.03401 18.4058 7.03401Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_420_6424">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
                <li>
                  <a>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8584 24V13.0533H17.5313L18.0824 8.78588H13.8584V6.06176C13.8584 4.82664 14.2 3.98492 15.9732 3.98492L18.231 3.98399V0.167076C17.8406 0.116334 16.5002 0 14.9403 0C11.6827 0 9.45258 1.98836 9.45258 5.63912V8.78588H5.76855V13.0533H9.45258V24H13.8584Z" fill="white"/>
                    </svg>
                  </a>
                </li>
                <li>
                  <a>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.5999 21.6V14.568C21.5999 11.112 20.8559 8.47205 16.8239 8.47205C14.8799 8.47205 13.5839 9.52805 13.0559 10.536H13.0079V8.78405H9.19189V21.6H13.1759V15.24C13.1759 13.56 13.4879 11.952 15.5519 11.952C17.5919 11.952 17.6159 13.848 17.6159 15.336V21.576H21.5999V21.6Z" fill="white"/>
                      <path d="M2.71191 8.784H6.69591V21.6H2.71191V8.784Z" fill="white"/>
                      <path d="M4.7039 2.40002C3.4319 2.40002 2.3999 3.43202 2.3999 4.70402C2.3999 5.97602 3.4319 7.03202 4.7039 7.03202C5.9759 7.03202 7.0079 5.97602 7.0079 4.70402C7.0079 3.43202 5.9759 2.40002 4.7039 2.40002Z" fill="white"/>
                    </svg>
                  </a>
                </li>
                <li>
                  <a>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_420_6433)">
                      <path d="M24 4.5585C23.1075 4.95 22.1565 5.2095 21.165 5.3355C22.185 4.7265 22.9635 3.7695 23.3295 2.616C22.3785 3.183 21.3285 3.5835 20.2095 3.807C19.3065 2.8455 18.0195 2.25 16.6155 2.25C13.8915 2.25 11.6985 4.461 11.6985 7.1715C11.6985 7.5615 11.7315 7.9365 11.8125 8.2935C7.722 8.094 4.1025 6.1335 1.671 3.147C1.2465 3.8835 0.9975 4.7265 0.9975 5.634C0.9975 7.338 1.875 8.8485 3.183 9.723C2.3925 9.708 1.617 9.4785 0.96 9.117C0.96 9.132 0.96 9.1515 0.96 9.171C0.96 11.562 2.6655 13.548 4.902 14.0055C4.5015 14.115 4.065 14.1675 3.612 14.1675C3.297 14.1675 2.979 14.1495 2.6805 14.0835C3.318 16.032 5.127 17.4645 7.278 17.511C5.604 18.8205 3.4785 19.6095 1.1775 19.6095C0.774 19.6095 0.387 19.5915 0 19.542C2.1795 20.9475 4.7625 21.75 7.548 21.75C16.602 21.75 21.552 14.25 21.552 7.749C21.552 7.5315 21.5445 7.3215 21.534 7.113C22.5105 6.42 23.331 5.5545 24 4.5585Z" fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_420_6433">
                          <rect width="24" height="24" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>

                  </a>
                </li>
              </ul>
            </div>
            <div className='col-5 col-md-4 d-flex justify-content-md-center'>
              <ul className='footer-links pl-0'>
                <li>
                  <h6 className='footer-service-title'>COMPANY</h6>
                </li>
                <li>
                  <a href='#'>Home</a>
                </li>
                <li>
                  <a href='#'>My Orders</a>
                </li>
                <li>
                  <a href='#'>Contact</a>
                </li>
                <li>
                  <a href='#'>Connect</a>
                </li>
              </ul>
            </div>
            <div className='col-7 col-md-4'>
              <ul className='footer-links pl-0 pl-lg-4'>
                <li>
                  <h6 className='footer-service-title'>CONTACT</h6>
                </li>
                <li>
                  <span className='link-icon'>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" opacity="0.5">
                      <path d="M1.00004 6.86053C2.91644 11.0344 6.32644 14.3529 10.5659 16.1519L11.2455 16.4547C12.8004 17.1475 14.6281 16.6212 15.5764 15.2077L16.4645 13.884C16.7533 13.4536 16.6654 12.8739 16.262 12.5485L13.2501 10.1187C12.8078 9.7618 12.1573 9.845 11.8188 10.3016L10.8871 11.5589C8.49634 10.3795 6.55524 8.4385 5.37594 6.04768L6.63314 5.11596C7.08984 4.77754 7.17295 4.12702 6.81605 3.68464L4.38623 0.672698C4.06088 0.269418 3.48137 0.181448 3.051 0.470018L1.71816 1.36372C0.295825 2.31742 -0.227555 4.16027 0.481175 5.71922L0.999265 6.85884L1.00004 6.86053Z" fill="white"/>
                    </svg>
                  </span>
                  <span>
                    <a href='tel:1234567890'>+ 01 1234567890</a>
                  </span>
                </li>
                <li>
                  <span className='link-icon'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                      <path d="M7.28934 4.90749C10.424 4.63501 13.5764 4.635 16.711 4.90749L18.2213 5.03877C19.2266 5.12617 20.0877 5.72584 20.5339 6.58362C20.5909 6.69335 20.5481 6.82621 20.443 6.89132L14.177 10.7703C12.8332 11.6022 11.1386 11.6196 9.77788 10.8156L3.47005 7.08821C3.36814 7.02799 3.32136 6.90403 3.36729 6.79493C3.77545 5.82536 4.69308 5.13318 5.77911 5.03878L7.28934 4.90749Z" fill="white"/>
                      <path d="M3.3623 8.76697C3.20633 8.67481 3.00733 8.77399 2.98915 8.95423C2.73542 11.4699 2.79683 14.0093 3.17338 16.5134C3.3719 17.8335 4.44931 18.8456 5.77925 18.9612L7.28948 19.0925C10.4241 19.365 13.5765 19.365 16.7111 19.0925L18.2214 18.9612C19.5513 18.8456 20.6287 17.8335 20.8272 16.5134C21.2148 13.9364 21.2685 11.3218 20.9885 8.73437C20.9688 8.55285 20.7665 8.4555 20.6112 8.5516L14.9667 12.0459C13.1485 13.1714 10.8559 13.195 9.01492 12.1072L3.3623 8.76697Z" fill="white"/>
                      </g>
                    </svg>

                  </span>
                  <span>
                    <a href='mailto:demo@gmail.com'>support@kizreviews.com</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='footer-left'>
          <p>Copyright © 2023 KizReviews All Rights Reserved</p>
          <div className='footer-bottom-links'>
            <span>|</span>
            <a href="/conditions">Terms and Policy</a>
          </div>
        </div>
      </div>
    </footer>

  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
const Failed = (props) => {

    const [dialogHeight, setDialogHeight] = useState(100);

    useEffect (() => {

        const setContentHeight = () => {
            let navbarHeight = document.querySelector('.header_section');
            let footerHeight = document.querySelector('.footer-main');

            console.log(navbarHeight)

            navbarHeight = navbarHeight ? navbarHeight.offsetHeight : 0;
            footerHeight = footerHeight ? footerHeight.offsetHeight : 0;

            let windowHeight = window.innerHeight;
            setDialogHeight(windowHeight - (navbarHeight + footerHeight));
        }

        setContentHeight();

        window.addEventListener('resize', setContentHeight());
    })



    return (
        <>
            <div 
                style={{minHeight : dialogHeight+'px'}}
                className='pt-5'
            >   
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img src='/close-circle.svg'></img>
                    </div>
                    <h1 className='text-center font-weight-bold mt-3'>PAYMENT FAILED</h1>
                    <p className='text-center'>Please <a href='/#ordering_section'>Try Again</a></p>
                </div>
            </div>
        </>
    );
}

export default Failed;
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import HCaptcha from "react-hcaptcha";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleUpdateUser, handleGetUser } from "../../calls";
import Logo from "../../images/Logo.svg";
import growYourBusiness from "../../images/signup-login-grow-your-business.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./account.css";

const axios = require("axios");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Account = (props) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    loader,
    setLoader,
    fullName,
    setFullName,
  } = props;

  const navigate = useNavigate();
  const [updateError, setUpdateError] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState([]);
  const [tokenSignUp, setTokenSignUp] = useState(null);
  const [trySignUp, setTrySignUp] = useState(false);
  const [isPassHidden, setPassHidden] = useState(true);
  const [isConfirmPassHidden, setConfirmPassHidden] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    handleGetUser(localStorage.getItem("accessToken"))
      .then((response) => {
        setUser(response.data);
        setUpdateData({
          first_name: response.data.first_name || "",
          last_name: response.data.last_name || "",
          email: response.data.email || "",
          phone_number: response.data.phone_number || "",
          password: "",
          password_confirmation: "",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [updateData, setUpdateData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    // Populate the input fields with user data
    setUpdateData({
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      email: user.email || "",
      phone_number: user.phone_number || "",
      password: "",
      password_confirmation: "",
    });
  }, [user]);

  const handleDataChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const captchaRefSignUp = useRef(null);

  const handlePassHidden = () => {
    setPassHidden(!isPassHidden);
  };

  const handleConfirmPassHidden = () => {
    setConfirmPassHidden(!isConfirmPassHidden);
  };

  const handleVerificationSuccess = (token, eKey) => {
    setTokenSignUp(token);
  };

  const handleSubmit = (e) => {
  e.preventDefault();

  const passwordsChanged = updateData.password || updateData.password_confirmation;
  const isIdentical = Object.keys(updateData)
    .filter((key) => key !== "password" && key !== "password_confirmation")
    .every((key) => updateData[key] === user[key]);

  if (!passwordsChanged && isIdentical) {
    setUpdateError(["No changes were made"]);
    return;
  }

  setTrySignUp(true);

  const handlePasswordUpdate = () => {
    handleUpdateUser(updateData)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("accessToken", response.data.accessToken);
          setUpdateSuccess([response.data.message]);
          updateUserData();
        } else {
          handleUpdateError(response.error);
        }
      })
      .catch(handleUpdateError);
  };

  const handleUpdateError = (error) => {
    console.error("Update user request failed");
    const errorMessage = error?.response?.status === 400 ? error.response.data.message : "An error occurred during update";
    setUpdateError([errorMessage]);
  };

  const updateUserData = () => {
    handleGetUser(localStorage.getItem("accessToken"))
      .then((response) => {
        const data = response.data;
        const fullname = `${data.first_name} ${data.last_name}`;
        localStorage.setItem("fullName", fullname);
        setFullName(fullname);
        
      })
      .catch((err) => console.error(err));
  };

  if (passwordsChanged) {
    handlePasswordUpdate();
  } else {
    const { password, password_confirmation, ...userDataWithoutPasswords } = updateData;
    handleUpdateUser(userDataWithoutPasswords)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("accessToken", response.data.accessToken);
          setUpdateSuccess([response.data.message]);
          setUpdateError([]);
          updateUserData();
        } else {
          handleUpdateError(response.error);
        }
      })
      .catch(handleUpdateError);
  }
};



  // reset state after successful sign up
  const resetAfterSignUp = () => {
    setConfirmPassHidden(true);
    setPassHidden(true);
    setTrySignUp(false);
    setUpdateData({
      first_name: user.first_name,
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
    });
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  // reset state after sign up error
  const resetAfterSignUpError = () => {
    setTrySignUp(false);
    setTokenSignUp(null);
    captchaRefSignUp.current.resetCaptcha();
  };

  const errorMsg = updateError.map((item, index) => {
    return (
      <h5 key={index} className="error_msg">
        {item}
      </h5>
    );
  });
  const successMsg = updateSuccess.map((item, index) => {
    return (
      <h5 key={index} className="success_msg">
        {item}
      </h5>
    );
  });

  return (
    <div>
      <div className="sign-up account-circle">
        <div className="left-account">
          <div className="hero-animation">
            <img src="/hero-animation.svg" alt="Hero Animation" />
          </div>

          <div className="grow-your-business">
            <img src={growYourBusiness} alt="Grow Your Business" />
          </div>
        </div>

        <div className="right-account">
          <div className="right-account-wrapper">
            <div className="d-flex justify-content-center mb-3 small-screen-logo">
              <a href="/" className="nav-link">
                <img src={Logo} alt="Logo" className="logo-img" />
                <span className="site_title">KizReviews</span>
              </a>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="sign-up-form">
                <h6>Update my informations</h6>
                {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
                {successMsg && <div className="mb-3 successm">{successMsg}</div>}
                <div className="mb-3">
                  <div className="mb-1">Full name</div>
                  <div className="d-flex">
                    <input
                      name="first_name"
                      className="form-control"
                      placeholder={"First Name"}
                      value={updateData.first_name}
                      onChange={handleDataChange}
                    />
                    
                    <input
                      name="last_name"
                      className="form-control ml-3"
                      placeholder="Last Name"
                      value={updateData.last_name}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="mb-1">Email</div>
                  <div className="d-flex">
                    <input
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={updateData.email}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="mb-1">Phone Number</div>
                  <div className="d-flex">
                    <input
                      name="phone_number"
                      className="form-control"
                      placeholder="Phone Number"
                      value={updateData.phone_number}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-1">Password</div>
                  <div className="password_field">
                    <input
                      type={isPassHidden ? "password" : "text"}
                      name="password"
                      className="form-control mb-2"
                      placeholder="Create New Password"
                      value={updateData.password}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={handlePassHidden}
                    ></i>
                  </div>
                  <div className="password_field">
                    <input
                      type={isConfirmPassHidden ? "password" : "text"}
                      name="password_confirmation"
                      className="form-control"
                      placeholder="Confirm New Password"
                      value={updateData.password_confirmation}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isConfirmPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={handleConfirmPassHidden}
                    ></i>
                  </div>
                </div>
                <div>
                  <button className="submit-btn" type="submit">
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;


import tripAdvisor from '../../images/tripAdvisor.png';
import googleReviews from '../../images/googleReviews.png';
import trustPilot from '../../images/trustpilot.png';
import siteJabber from '../../images/siteJabber.png';
import Stroke from '../../images/Stroke.svg';
import rocketIcon from '../../images/rocket.svg';
import growYourBusinessXL from '../../images/grow_your_business_xl.png';
import growYourBusinessOverlayXl from '../../images/grow_your_business_overlay_xl.png';

import growYourBusinessSM from '../../images/grow_your_business_sm.png';
import growYourBusinessOverlaySM from '../../images/grow_your_business_overlay_sm.png';

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate} from "react-router-dom";
import { handleCreateOrderGuest, handleCreateOrderClient} from "../../calls";



const Home = (props) => {
 
  const [commentSections, setCommentSections] = useState([0]); // Start with one section
  const [comments, setComments] = useState(['']); // Start with one empty comment
  const [hideComments, setHideComments] = useState(false);


  const navigate = useNavigate();
  const [OrderData, setOrderData] = useState({
    email: "",
    websiteUrl: "",
    service: "Google",
    quantity: 1,
    rating: 5,
    price: 3.95,
    Comments: [],
    is_guest:false
  });

  const [calculatedPrice, setCalculatedPrice] = useState(3.95);

  // useEffect to calculate the price whenever quantity changes
  useEffect(() => {
    // Multiply quantity by 5.00 to calculate the price
    const newPrice = OrderData.quantity * 3.95;

    // Update both OrderData and calculatedPrice
    setOrderData({
      ...OrderData,
      price: newPrice,
    });
    setCalculatedPrice(newPrice);
  }, [OrderData.quantity]);
  const handleServiceButtonClick = (e) => {
  const clickedButton = e.target;
  const service = clickedButton.value;

  const buttons = document.getElementsByClassName('service-btn');
  
  // Deactivate all buttons
  for (let i = 0; i < buttons.length; i++) {
    if (buttons[i] !== clickedButton) {
      buttons[i].classList.remove('service-active');
    }
  }
  
  // Toggle the class on the clicked button
  clickedButton.classList.toggle('service-active');

  setOrderData({
    ...OrderData,
    service: service,
  });
};
  const { 
    isLoggedIn,
    fullName,
    setFullName,
  } = props;
  const location = useLocation();

  const [orderError, setOrderError] = useState([]);

  
  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash) {
        document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }, 500)

  }, [])


  const handleAddComment = () => {
    setCommentSections((sections) => [...sections, sections.length]);
    setComments((prevComments) => [...prevComments, '']); // Add an empty comment
  };

  // Function to remove the last comment section
  const handleRemoveComment = () => {
    if (commentSections.length > 1) {
      setCommentSections((sections) => sections.slice(0, -1));
      setComments((prevComments) => prevComments.slice(0, -1));
    }
  };

  // Function to handle comment input change
const handleCommentChange = (e, sectionIndex) => {
  const newComments = [...comments];
  newComments[sectionIndex] = e.target.value;
  setComments(newComments);

  // Update OrderData with the new comments list
  setOrderData({
    ...OrderData,
    Comments: newComments.filter((comment) => comment && comment.trim() !== ''), // Check if comment is defined before calling trim
  });
};


// Function to handle toggling of comments display
const handleToggleComments = () => {
  console.log(!hideComments);
  if (!hideComments) {
    // If comments are hidden, call the resetCommentsArray function
    setCommentSections([0]);
    setComments([]); // Reset comments to an empty array
    setOrderData({
      ...OrderData,
      Comments: [], // Reset Comments array
    });
    console.log(OrderData.Comments,commentSections);
  }
  setHideComments(!hideComments); // Toggle the visibility
  setOrderData((prevOrderData) => {
    return prevOrderData; // Return the previous state to avoid any unintended changes
  });
};

  //   const navigate = useNavigate();

  //   const location = useLocation();
  //   const { pathname } = location;
  //   const { t } = useTranslation();


  const handleDataChange = (e) => {
    setOrderData({
      ...OrderData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
  e.preventDefault();
  console.log(OrderData);
  if (!OrderData.service) {
    setOrderError(["No service was selected"])

    return; // Prevent further execution
  }
  const updatedOrderData = { ...OrderData };


  const isGuest = !isLoggedIn;
  updatedOrderData.is_guest = isGuest;

  const handleOrderCreation = isGuest ? handleCreateOrderGuest : handleCreateOrderClient;
  const accessToken = isGuest ? null : localStorage.getItem('accessToken');
  handleOrderCreation(updatedOrderData, accessToken)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        window.location.href = `${response.data["paymentLink"]}`;
      }
    })
    .catch((err) => {
      if (err?.response?.data?.Message) {
        setOrderError([err.response.data.Message]);
      }
    });
};


  const errorMsg = orderError.map((item, index) => (
    <h5 key={index} className="error_msg">
      {item}
    </h5>
  ));

  return (
    <>
      <div className="hero_area">

        <section className="slider_section position-relative">
          <div className="container d-md-flex align-items-center">
            <div className='top-50 end-0 hero-character'>
              <img src='/hero-animation.svg' className=''></img>
            </div>
            <div className="row hero-texts">
              <div className="col-12">
                <div className="detail_box">
                  <h1 className='text-nowrap d-none'>
                    Propulsez Votre Commerce!
                  </h1>
                  <div className='d-flex justify-content-center home-title-img'>
                    <img src={growYourBusinessXL} className='home-title-gradiant-xl'></img>
                    <img src={growYourBusinessOverlayXl} className='overlay-img-xl'></img>
                    
                    <img src={growYourBusinessSM} className='home-title-gradiant-sm'></img>
                    <img src={growYourBusinessOverlaySM} className='overlay-img-sm'></img>
                  </div>
                  <h2 className='text-center text-md-left'>
                    Avec KizReviews, augmentez votre visibilité et gagner des parts de marché !
                  </h2>
                  <div className='text-center text-md-left'>
                    <a href="contact" className='contact-btn'>Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='about_section'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 bg-image d-none d-md-block">
                <img src='./about-animation.svg' alt="background image" className='w-100' />
              </div>
              <div className='d-flex justify-content-center col-12 d-md-none'>
                <img src={rocketIcon} alt=''/>
              </div>
              <div className="col-md-6">
                <div className="detail-box">
                  <div className="heading_container">
                    <h2>Qui sommes nous ?</h2>
                    <img src={Stroke} alt="" className='w-50' />
                  </div>
                  <p>
                    KizReviews offre aux commerçants et entrepreneurs l'opportunité d'accroître la qualité de leur référencement en leur fournissant des avis Google positifs de qualité !
                  </p>
                  <ul className='mx-auto detail-box'>
                    <li>Les études révèlent que plus de 50 % des consommateurs n’opteront pas pour une entreprise qui affiche une note inférieure à étoiles.</li>
                    <li>Les produits les plus vendus affichent une note entre 4,2 et 4,7, seuil qui représenterait, selon les experts, le point de crédibilité.</li>
                    <li>Après plusieurs mois d'inactivité pendant la crise sanitaire et les nombreux mouvements sociaux touchant la France, la fréquentation des restaurants et petits commerces à baisser d'environ 25%</li>
                    <li>Ne soyez plus dépendants des plateformes de livraisons aux grosses commissions pour générer du traffic et consolider votre marque! En boostant votre référencement Google, vous reprenez le controle sur votre visibilité et votre image de marque.</li>
                    <li>Vous pouvez maintenant vous concentrer ce qui interesse vraiment les clients : votre service !</li>
                    <li>Choisir KizReviews c'est opter pour un service fiable et sécurisé aligné avec les objectifs de votre business !</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="service_section layout_padding">
          <div className="container">
            <div className="heading_container">
              <h2>
                Nos services
              </h2>
              <p>
                Nous opérons sur divers services et pouvons accomoder vos besoins particuliers !
              </p>
            </div>
            <div className="service_container">
              <div className="box">
                <div className="img-box">
                  <img src={tripAdvisor} alt='trip advisor logo' />
                </div>
                <div className="detail-box ">
                  <h5>
                    Tripadvisor
                  </h5>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <img src={trustPilot} alt='trust pilot logo' />
                </div>
                <div className="detail-box">
                  <h5>
                    TrustPilot
                  </h5>
                </div>
              </div>
              <div className="box">
                <div className="img-box">
                  <img src={googleReviews} alt='google reviews logo' />
                </div>
                <div className="detail-box">
                  <h5>
                    Google
                  </h5>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="need_section layout_padding">
          <div className="container">
            <div className='row'>
              <div className='col-12'>
                <div className='box w-100'>
                  <h2 className='text-center text-md-start'>Vous nécessitez un service personnalisé ?</h2>
                  <p>Faites-nous en part, nous accomoderons une solution adaptée à vos besoin !</p>
                  <a href='/contact'>Nous contacter</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="work_section layout_padding">
          <div className="container">
            <div className="heading_container">
              <h2 className='text-center text-md-start'>
                Comment travaillons-nous?
              </h2>
            </div>
            <div className="row">
              <div className="col-md-1">
                <span className="bullet-point"></span>
              </div>
              <div className="col-md-11">
                <div className="detail_container">
                  <div className="box b-1">
                    <div className="text-box">
                      <h5>
                        Nous recevons de votre part la commande
                      </h5>
                      <p>
                        Il vous suffit de nous indiquer le nombre d'avis désiré, d'ajouter des commentaires personnalisés, de préciser la période de publication souhaitée ainsi que les références spécifiques, et nous nous chargeons du reste.
                      </p>
                    </div>
                  </div>
                  <div className="box b-2">
                    <div className="text-box">
                      <h5>
                        Nous l'intégrons à notre plateforme automatisée
                      </h5>
                      <p>
                        Notre plateforme automatisée assure la fiabilité et la sécurité de chaque requête. Nous réagissons promptement pour initier des campagnes de publication d'avis visant à renforcer la réputation de votre entreprise. Cette approche vous permet d'améliorer votre visibilité en ligne, d'attirer de nouveaux clients et d'établir la confiance au sein de votre public.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='ordering_section' className="ordering_section">
          <div className='heading_container'>
            <h2 className='text-center text-start'>
              Commandez vos avis sans plus attendre!
            </h2>
            <h3 className='text-center text-start'>
              Choisissez un service de référencement
            </h3>
          </div>
          <div className="container">
            <div className="row">
              <div className='ordering_container col-md-12 pt-1 pb-1 mx-auto'>
                <div className="col-md-12 mx-auto service_info_col">
                  <div class="row service-buttons">
                    <div class="col-6 col-md-12">
                      <button
                        className='service-btn'
                        value="Google"
                        name="service"
                        onClick={(e) => handleServiceButtonClick(e)}>
                        <img src={googleReviews} className='order-item-btn-logo' alt='Google' />
                        Google
                      </button>
                    </div>
                    <div class="col-6 col-md-12">
                      <button
                        className='service-btn'
                        value="TripAdvisor"
                        name="service"
                        onClick={(e) => handleServiceButtonClick(e)}
                        >
                        <img src={tripAdvisor} className='order-item-btn-logo' alt='TripAdvisor'/>
                        TripAdvisor
                      </button>
                    </div>
                    <div class="col-6 col-md-12">
                      <button
                        className='service-btn'
                        value="TrustPilot"
                        name="service"
                        onClick={(e) => handleServiceButtonClick(e)}>
                        <img src={trustPilot} className='order-item-btn-logo' alt='TrustPilot'/>
                        TrustPilot
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div className='col-lg-6 mb-4'>
                    <div class='newOrder'>
                      {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}

                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          {!isLoggedIn && (
                            <div className={`form-group col-12`}>
                              <label className="control-label" htmlFor="inputEmail">
                                Adresse mail
                              </label>
                              <input
                                required
                                value={OrderData.email}
                                onChange={handleDataChange}
                                type="email"
                                name="email"
                                className="form-control"
                                id="inputEmail"
                                placeholder="Saisissez votre adresse mail"
                              />
                            </div>
                          )}
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12">
                            <label class="control-label" for="inputUrl">Lien vers votre page d'avis</label>
                            <input required
                              value={OrderData.websiteUrl}
                              onChange={handleDataChange}
                              name="websiteUrl"
                              type="text"
                              class="form-control"
                              id="inputUrl"
                              placeholder="Exemple: https://goo.gl/maps/2LieCVu6JUWwL9Bk9" />
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col">
                            <label class="control-label" for="inputQuantity">Quantité</label>
                            <input required
                              value={OrderData.quantity}
                              onChange={handleDataChange}
                              name="quantity"
                              type="number"
                              class="form-control"
                              id="inputQuantity" />
                          </div>
                          <div class="form-group col-12">
                            <label class="control-label" for="inputRating">Niveau des avis</label>
                            <select
                              value={OrderData.rating}
                              onChange={handleDataChange}
                              name="rating"
                              class="form-control"
                              id="inputRating">
                              <option value='5'>5 étoiles </option>
                              <option value='4'>4 étoiles </option>
                              <option value='3'>3 étoiles </option>
                              <option value='5'>2 étoiles </option>
                              <option value='4'>1 étoile </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12">
                            <label class="control-label" for="displayPrice">Prix</label>
                            <div
                              class='form-control'
                              value={OrderData.price}
                              onChange={handleDataChange}
                              name="price"
                              id="displayPrice">
                              {calculatedPrice.toFixed(2)}
                            </div>
                          </div>
                        </div>
                        <div className="form-group col text-center">
                          <label className="form-check-label custom-checkbox-label">
                            <input
                              type="checkbox"
                              className="form-check-input display-checkbox"
                              checked={hideComments}
                              onChange={handleToggleComments}
                            />
                            <span className="custom-checkbox-text">
                              {hideComments ? 'Rédiger les commentaires' : 'Nous laisser rédiger les commentaires (gratuit)'}
                            </span>
                          </label>
                        </div>
                          {!hideComments && (
                          <div>
                            {commentSections.map((sectionIndex) => (
                              <div className="form-row" key={sectionIndex}>
                                <div className="form-group col">
                                  <label className="control-label" htmlFor={`comment-${sectionIndex}`}>
                                    Commentaires.

                                  </label>
                                  <textarea
                                    onChange={(e) => handleCommentChange(e, sectionIndex)}
                                    value={comments[sectionIndex]}
                                    className="form-control"
                                    name={`Comments-${sectionIndex}`}
                                    id={`comment-${sectionIndex}`}
                                    rows="1"
                                  ></textarea>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="form-row pb-3 options">
                          {!hideComments && (
                          <div className="form-group col">
                            <div className="w-100 text-right d-flex justify-content-end">
                              <button
                                type="button"
                                className="px-2 add-comment-btn"
                                onClick={handleAddComment}
                              >
                                +
                              </button>
                              <button
                                type="button"
                                className="px-2 remove-comment-btn"
                                onClick={handleRemoveComment}
                              >
                                -
                              </button>
                            </div>
                          </div>
                          )}
                        </div>
                        <div className="form-row pb-3">
                          <div className="w-100 text-right">
                            <button
                              type="submit"
                              className="px-5 submit-btn"
                            >
                              Commander
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='col-lg-6 mb-4 '>
                    <div class='newOrderInfos'>
                      <div class ='d-flex'>
                        <h5 class="mb-0">NEW ORDER</h5>
                      </div>
                      <div class='content'>
                        <div class='media'>
                          <div class ='media-body'>
                           {OrderData.service} Review [CUSTOM] [Garantie fill: 30 Days] [Start Time: 0 - 12 Hours] - €{OrderData.price}
                          </div>
                        </div>
                        <div class='infos'>
                          <div class='info'>
                            <span>START TIME</span>
                            <small>1 Hour</small>
                          </div >
                          <div class='info'>
                            <span>SPEED</span>
                            <small> Default</small>
                          </div>
                          <div class='info'>
                            <span>GUARANTEED</span>
                            <small> FILL: 30 Days</small>
                          </div>
                          {/* <div class='info'>
                            <span>AVERAGE TIME</span>
                            <small>13 minutes</small>
                          </div > */}
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Logo from "../../images/Logo.svg";
import growYourBusiness from "../../images/signup-login-grow-your-business.png";
import { resetPassword } from "../../calls";
import { useNavigate, useParams  } from "react-router-dom";
import "./reset.css";
import { handleGetUser } from "../../calls";

Modal.setAppElement("#root");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Reset = (props) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setShowModal2,
    setShowModal,
    showModal2,
    loader,
    setLoader,
    fullName,
    setFullName,
  } = props;
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [resetData, setResetData] = useState({
    password:"",
    password_confirmation:"",
    token:token
  });

  const [isPassHidden, setPassHidden] = useState(true);
  const [isConfirmPassHidden, setConfirmPassHidden] = useState(true);
  const [resetError, setResetError] = useState([]);
  const [resetSuccess, setResetSuccess] = useState([]);
  const [token_login, setTokenLogIn] = useState(null);
  const [trySubmit, setTrySubmit] = useState(false);
  const captchaRef = useRef(null);

  
  const handlePassHidden = () => {
    setPassHidden(!isPassHidden);
  };

  const handleDataChange = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  const handleVerificationSuccess = (token, eKey) => {
    setTokenLogIn(token);
  };

  const handleSubmit = (e) => {
  e.preventDefault();
  setTrySubmit(true);
  
  // Check if passwords match
  if (resetData.password !== resetData.password_confirmation) {
    setResetError(["Passwords do not match"]);
    console.log(resetError);
    return;
  }

  resetPassword(resetData, token_login)
    .then((response) => {
      if (response.success) {
        setResetSuccess([response.data.message])
        setResetError([])
        console.log(response.data); // Successful response data
        localStorage.setItem("accessToken", response.data.accessToken);
        handleGetUser(localStorage.getItem("accessToken"))
          .then((response) => {
            console.log(response);
            const data = response.data;
            const fullname = `${data.first_name} ${data.last_name}`
            setFullName(fullname);
            setIsLoggedIn(true);
            navigate('/');
          })
      }
      else {
        handleUpdateError(response.error);
      }
    })
    .catch(handleUpdateError);
};

  const handleUpdateError = (error) => {
    const errorMessage = error?.response?.status === 400 ? error.response.data.message : "An error occurred during update";
    setResetError([errorMessage]);
    setResetSuccess([]);
  };
  
  const errorMsg = resetError.map((item, index) => (
    <h5 key={index} className="error_msg">
      {item}
    </h5>
  ));
  const successMsg = resetSuccess.map((item, index) => {
    return (
      <h5 key={index} className="success_msg">
        {item}
      </h5>
    );
  });
  return (
    <div className="reset-circle">
      <div className="reset">
        <div className="left-reset">
          <div className="hero-animation">
            <img src="/hero-animation.svg" className="hero-animation" />
          </div>

          <div className="grow-your-business">
            <img src={growYourBusiness} />
          </div>
        </div>

        <div className="right-reset">
          <div className="right-reset-wrapper">
            <div className="d-flex justify-content-center mb-3 small-screen-logo">
              <a href="/" className="nav-link">
                <img src={Logo} alt="" className="logo-img" />
                <span className="site_title">KizReviews</span>
              </a>
            </div>
            <div className="reset-form">
              <form onSubmit={handleSubmit}>
                <h6>Reset my password</h6>
                {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
                {successMsg && <div className="mb-3 successm">{successMsg}</div>}
                <div className="mb-1">Password</div>
                  <div className="password_field">
                    <input
                      required
                      type={isPassHidden ? "password" : "text"}
                      name="password"
                      className="form-control mb-2"
                      placeholder="Create Password"
                      value={resetData.password}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={() => setPassHidden(!isPassHidden)}
                    ></i>
                  </div>
                  <div className="password_field">
                    <input
                      required
                      type={isConfirmPassHidden ? "password" : "text"}
                      name="password_confirmation"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={resetData.password_confirmation}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isConfirmPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={() => setConfirmPassHidden(!isConfirmPassHidden)}
                    ></i>
                  </div>
                <div>
                </div>
                <div>
                  <button className="submit-btn" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;

import Footer from '../footer/footer';
import React, { useState, useEffect } from 'react';
import { handleGetOrders } from '../../calls';
import './orders.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const Orders = () => {

  console.log(localStorage.getItem("accessToken"))
  const [orders, setOrders] = useState([
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
    {'orderId': 1, createdAt: '12 07 2022', quantity: 2, price: 50, progress: 'processing'},
  ]);

  const getOrderList = () => {
    handleGetOrders()
      .then((response) => {
        setOrders(response.data);
      });
  };

  useEffect(() => {
    getOrderList();
  }, []);

  function formatDate(createdAt) {
  const date = new Date(createdAt);
const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')} ${date.getDate().toString().padStart(2, '0')} ${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
return formattedDate;

}
  return (
    <>
      <section className="orders_section layout_padding">
        <div className='heading_container text-center'>
          <h2>Historique de vos commandes</h2>
        </div>
        <div className="container orders_container">
          <div className="row without-padding">
            <div className='orders_box'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">N° Commande</th>
                    <th scope="col">Date</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix</th>
                    <th scope="col">Avancement</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr key={index}>
                      <td>
                        <h6>N° Commande</h6>
                        {order.orderId}
                      </td>
                      <td>
                        <h6>Date</h6>
                        {formatDate(order.createdAt)}
                      </td>
                      <td>
                        <h6>Quantité</h6>
                        {order.quantity}
                      </td>
                      <td>
                        <h6>Prix</h6>
                        {order.price}€
                      </td>
                      <td className='col-span'>
                        <h6>Avancement</h6>
                        {order.progress}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;

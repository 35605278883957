import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Logo from "../../images/Logo.svg";
import growYourBusiness from "../../images/signup-login-grow-your-business.png";
import { handleLogin, handleGetUser } from "../../calls";
import { useNavigate } from "react-router-dom";
import "./login.css";

Modal.setAppElement("#root");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Login = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isLoggedIn,
    setIsLoggedIn,
    fullName,
    setFullName,
    loader,
    setLoader,
  } = props;

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [isPassHidden, setPassHidden] = useState(true);
  const [loginError, setLoginError] = useState([]);
  const [loginSuccess,setLoginSuccess] = useState([]);
  const [token_login, setTokenLogIn] = useState(null);
  const [trySubmit, setTrySubmit] = useState(false);
  const captchaRef = useRef(null);
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  });
  
  const handlePassHidden = () => {
    setPassHidden(!isPassHidden);
  };

  const handleDataChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleVerificationSuccess = (token, eKey) => {
    setTokenLogIn(token);
  };

  const handleSubmit = (e) => {
  e.preventDefault();
  setTrySubmit(true);
  setLoader(false);
  handleLogin(loginData)
    .then((response) => {
      if (response.success) {
        console.log(response.data); // Successful response data
        localStorage.setItem("accessToken", response.data.accessToken);
        handleGetUser(localStorage.getItem("accessToken"))
            .then((response) => {
              const data = response.data;
              const fullname = `${data.first_name} ${data.last_name}`
              setFullName(fullname);
              //const Success = data.message;
              //setLoginSuccess([Success]);
              setIsLoggedIn(true);
            })
      } else {
        console.error('Login request failed');
        const error = response.error;
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.message;
          setLoginError([errorMessage])
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
};





  // Reset states after successful login
  const resetStateLogin = () => {
    setLoginData({ email: "", password: "" });
    setLoginError([]);
    setPassHidden(true);
    setTrySubmit(false);
    setTokenLogIn(null);
    captchaRef.current.resetCaptcha();
  };

  const errorMsg = loginError.map((item, index) => (
    <h5 key={index} className="error_msg">
      {item}
    </h5>
  ));
  const successMsg = loginSuccess.map((item, index) => (
    <h5 key={index} className="success_msg">
      {item}
    </h5>
  ));

  return (
    <div className="login-circle">
      <div className="login">
        <div className="left-login">
          <div className="site-logo">
            <a href="/" className="nav-link">
              <img src={Logo} alt="" className="logo-img" />
              <span className="site_title">KizReviews</span>
            </a>
          </div>
          <div className="hero-animation">
            <img src="/hero-animation.svg" className="hero-animation" />
          </div>

          <div className="grow-your-business">
            <img src={growYourBusiness} />
          </div>
        </div>

        <div className="right-login">
          <div className="right-login-wrapper">
            <div className="d-flex justify-content-center mb-3 small-screen-logo">
              <a href="/" className="nav-link">
                <img src={Logo} alt="" className="logo-img" />
                <span className="site_title">KizReviews</span>
              </a>
            </div>
            <div className="login-form">
              <form onSubmit={handleSubmit}>
                <h6>Login</h6>
                <p className="login-title">
                  Don't have an account? <a href="/sign-up">Register</a>
                </p>
                {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
              {/*successMsg && <div className="mb-3 successm">{successMsg}</div>*/}
                <div className="mb-3">
                  <div className="mb-1">Email</div>
                  <div className="d-flex">
                    <input
                      required
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={loginData.email}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-1">Password</div>
                  <div className="password_field">
                    <input
                      required
                      type={isPassHidden ? "password" : "text"}
                      name="password"
                      className="form-control mb-2"
                      placeholder="Create Password"
                      value={loginData.password}
                      onChange={handleDataChange}
                    />
                    <i
                      className={`bi ${
                        isPassHidden ? "bi-eye" : "bi-eye-slash"
                      } eye`}
                      onClick={() => setPassHidden(!isPassHidden)}
                    ></i>
                  </div>
                  <div className="text-right">
                    <a href="/forget-password" className="forgot-password">
                      Forgot password
                    </a>
                  </div>
                </div>
                <div>
                  <button className="submit-btn" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

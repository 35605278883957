import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import './order.css';
import axios from 'axios';

const FollowOrders = () => {
  const { orderid } = useParams(); 
  
  const [order, setOrder] = useState({});
  const getOrderDetails = async () => {
    try {
      const response = await axios.get(`http://localhost:3001/order/${orderid}`);
      setOrder(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getOrderDetails();
  }, [orderid]); // Include orderId in the dependency array

  function formatDate(createdAt) {
    const date = new Date(createdAt);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return formattedDate;
  }

  return (
    <>
      <section className="order_section layout_padding">
        <div className='heading_container text-center'>
          <h2>Votre Commande</h2>
        </div>
        <div className="container order_container">
          <div className="row">
            <div className='order_box'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">N° Commande</th>
                    <th scope="col">Date</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Prix</th>
                    <th scope="col">Avancement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>N° Commande</h6>
                      {order.orderId}
                    </td>
                    <td>
                      <h6>Date</h6>
                      {formatDate(order.createdAt)}
                    </td>
                    <td>
                      <h6>Quantité</h6>
                      {order.quantity}
                    </td>
                    <td>
                      <h6>Prix</h6>
                      {order.price}€
                    </td>
                    <td>
                      <h6>Avancement</h6>
                      {order.progress}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FollowOrders;


import Footer from '../footer/footer'
import './conditions.css'
import { useEffect } from 'react';
import Accordion from "react-bootstrap/Accordion";


const Conditions = () => {
  return (
    <>
    <section class="condition_section layout_padding">
      <div class="heading_container">
        <h2>
          Conditions générales d'utilisation
        </h2>
      </div>
      <div class="container">
        <div class="row">
          <div class='accordion_box'>
            <Accordion className="accordion">
              <Accordion.Item eventKey="0" class='item'>
                <Accordion.Header className="question">
                  <span class='question_title'>Politique de confidentialité</span>
                </Accordion.Header>
                <Accordion.Body className="answer">
                  <ul>
                    <li>
                      <h3> Stockage des données personnelles</h3>
                      <p>Nous nous engageons à recueillir votre consentement éclairé pour la collecte et le traitement de vos données personnelles.</p>
                    </li>
                    
                    <li>
                      <h3> Partage de vos informations</h3>
                      <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
                      <ul>
                        <li>Lorsque la loi l'exige ou pour se conformer à une procédure judiciaire.</li>
                        <li>Pour protéger les droits et la sécurité de notre entreprise et de nos utilisateurs.</li>
                        <li>Avec votre consentement exprès.</li>
                      </ul>
                    </li>
                    
                    <li>
                      <h3> Traitement des cookies</h3>
                      <p>Chez KizReviews, nous utilisons des cookies pour optimiser votre expérience de navigation et nous assurer que le contenu de notre site Web est correctement affiché.</p>
                      <p>En utilisant ces cookies, nous nous efforçons de rendre votre expérience de navigation sur notre site Web aussi transparente et pratique que possible.</p>
                      <p>En résumé, nous utilisons des cookies pour rendre votre expérience sur KizReviews plus personnalisée et pratique, tout en préservant votre confidentialité et votre sécurité.</p>
                    </li>
                    
                    <li>
                      <h3> Sécurité</h3>
                      <p>Nous prenons la sécurité de vos informations personnelles au sérieux et avons mis en place des mesures appropriées pour les protéger contre tout accès, utilisation ou divulgation non autorisés.</p>
                    </li>
                    
                    <li>
                      <h3> Modifications de notre politique de confidentialité</h3>
                      <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre pour refléter les changements apportés à nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.</p>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" class='item'>
                <Accordion.Header className="question">
                  <span class='question_title'>Conditions d'utilisation</span>
                </Accordion.Header>
                <Accordion.Body className="answer">
                  <ul>
                  <li>
                    <h3>Disponibilité du service</h3>
                    <p>La Société ne garantit pas la disponibilité du Service et ne sera pas responsable des éventuelles interruptions du Service.</p>
                  </li>
                  <li>
                    <h3>Confidentialité</h3>
                    <p>La Société respecte votre vie privée et s'engage à protéger vos informations personnelles.</p>
                  </li>
                  <li>
                    <h3>Limitation de responsabilité</h3>
                    <p>La Société ne sera pas responsable des dommages résultant de l'utilisation du Service, y compris, mais sans s'y limiter, les dommages directs, indirects, accessoires, consécutifs ou punitifs.</p>
                  </li>
                  <li>
                    <h3>Conditions Générales</h3>
                    <p>Les services seront fournis conformément aux termes et conditions énoncés dans le présent contrat et toutes les annexes qui l'accompagnent, ainsi qu'aux termes et conditions affichés sur le site Web concerné.</p>
                    <ul>
                      <li>
                        <p>En cas de conflit entre les termes et conditions affichés sur le site Web et ceux décrits dans le présent contrat ou ses annexes, les termes et conditions affichés sur le site Web prévaudront.</p>
                      </li>
                      <li>
                        <p>Les services ne seront fournis au Client que dans les conditions suivantes : le Client s'est correctement inscrit sur le site et le Prestataire a confirmé sa capacité à fournir le service en envoyant un message à l'e-mail fourni lors de l'inscription.</p>
                      </li>
                      <li>
                        <p>Les Services seront fournis au Client sur la base du Tarif et du type de service sélectionné.</p>
                      </li>
                      <li>
                        <h3>Modifications des termes et conditions</h3>
                        <p>La Société se réserve le droit de modifier à tout moment les présentes conditions générales.</p>
                      </li>
                    </ul>
                  </li>
                 </ul> 


                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" class='item'>
                <Accordion.Header className="question">
                  <span class='question_title'>À propos de l'inscription</span>
                </Accordion.Header>
                <Accordion.Body className="answer">
                  
                <ul>
                  <li>
                    <h3>À propos de l'inscription</h3>
                    <p>La Société est responsable de la gestion des soldes des comptes clients et de la gestion sûre et sécurisée des fonds.<br></br>
                      En s'inscrivant sur le site Web, les clients consentent au traitement de leurs informations personnelles conformément à la politique de confidentialité.

                Les clients peuvent révoquer ce consentement à tout moment en envoyant une demande à support@kizreviews.com.</p>
                  </li>
                  <li>
                    <h3>Procédure de dépôt</h3>
                    <p>Pour effectuer un dépôt, le client doit initier une demande depuis son profil personnel.

                    De plus, en acceptant ces conditions, vous, en tant que titulaire de la carte, confirmez que vous avez le pouvoir d'utiliser les services offerts sur le site Web.</p>
                  </li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" class='item'>
                <Accordion.Header className="question">
                  <span class='question_title'>Politique de retour</span>
                </Accordion.Header>
                <Accordion.Body className="answer">
                  
                  <h3>KizReviews n'émet pas de remboursement pour les achats d'avis clients qui ont été activés avec succès.<br></br>Les conditions : </h3>
                  <ul>
                    <li>La non-réception des avis, en raison de problèmes de serveur avec un service, doit être signalée dans les 7 jours suivant l'achat pour obtenir de l'aide.</li>
                    <li>Les défauts majeurs, avec un problème signalé et un défaut de correction dans les 72 heures, sont éligibles pour un remboursement complet ou un remplacement.</li>
                    <li>Un accès temporaire à votre hébergeur/serveur peut être demandé par nos techniciens pour identifier et résoudre les problèmes.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      </section>
</>
);
};


export default Conditions;

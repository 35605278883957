import React, { useState,useEffect } from 'react'; 
import axios from 'axios';
const API_URL = "https://api.kizreviews.app"

const accessToken = localStorage.getItem("accessToken");



export const handleSignup = async (signupData) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, {
      first_name: signupData.first_name,
      last_name: signupData.last_name,
      email: signupData.email,
      phone_number: signupData.phone_number,
      password: signupData.password,
    });
    return { success: true, data: response.data }; // Return success with data
  } catch (error) {
    console.log(error)
    return { success: false, error }; // Return failure with error
  }
};


export const handleLogin = async (loginData) => {

    try {
      const response = await axios.post(`${API_URL}/login`, {
        email:loginData.email,
        password:loginData.password,
      });
      return { success: true, data: response.data }; // Return success with data
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  };

export const handleForgotPassword = async (forgetData) => {
  try {
      const response = await axios.post(`${API_URL}/forget-password`, {
        email:forgetData.email,
      });
    return { success: true, data: response.data }; // Return success with data
  } catch (error) {
    console.log(error)
    return { success: false, error }; // Return failure with error
  }
};


export const resetPassword = async (resetData) => {
  console.log(resetData);
  try {
    const response = await axios.post(`${API_URL}/reset-password/${resetData.token}`, {
      token : resetData.token,
      newPassword:resetData.password,
    });
    return { success: true, data: response.data }; // Return success with data
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  };

export const handleUpdateUser = async (updateData) => {
  try {
    const response = await axios.post(
      `${API_URL}/update-user`,
      {
        first_name: updateData.first_name,
        last_name: updateData.last_name,
        email: updateData.email,
        phone_number: updateData.phone_number,
        password: updateData.password,
      },
      {
        headers: {
          authorization: accessToken,
        },
      }
    );
    return { success: true, data: response.data }; // Return success with data
  } catch (error) {
      console.log(error);
      return { success: false, error };
    }
};

export const handleCreateOrderGuest = async (orderData) => {
  console.log(orderData);
  try {
    const response = await axios.post(
      `${API_URL}/create-order/as_guest`,
      orderData,
    );

    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    
  }
};

export const handleCreateOrderClient = async (orderData,accessToken) => {
    try {
      const response = await axios.post(
        `${API_URL}/create-order/as_client`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: accessToken,
          },
          
        }
      );
      return {
      
      status: response.status,
      data: response.data,
    };
    } catch (error) {
      console.error(error);
    }
  };

  

export const handleGetUser = async (accessToken) => {
    try {
      const response = await axios.get(`${API_URL}/get-user`,
      {
        headers: {
          authorization: accessToken,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

export const handleGetOrders = async () => {

    try {
      const response = await axios.get(`${API_URL}/order-list`,
      {
        headers: {
          authorization: accessToken,
        },
      });
      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
export const handleContactClient = async (ContactData) => {
    try {
      const response = await axios.post(`${API_URL}/contact/as_client`,
      ContactData,
      {
        headers: {
          authorization: accessToken,
        },
      });
      return { success: true, data: response.data }; // Return success with data
  } catch (error) {
      console.log(error);
      return { success: false, error };
    }
};
export const handleContactGuest = async (ContactData) => {
  try {
    const response = await axios.post(
      `${API_URL}/contact/as_guest`,
      ContactData 
    );
    return { success: true, data: response.data }; // Return success with data
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};


import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Logo from "../../images/Logo.svg";
import growYourBusiness from "../../images/signup-login-grow-your-business.png";
import { handleForgotPassword } from "../../calls";
import { useNavigate } from "react-router-dom";
import "./forget.css";

Modal.setAppElement("#root");

const sitekey = "4c3e00a9-8947-458a-8e06-46df45f1ae1e";

const Forget = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [forgetData, setForgetData] = useState({
    email: "",

  });


  const [forgetError, setForgetError] = useState([]);
  const [forgetSuccess, setForgetSuccess] = useState([]);

  const handleDataChange = (e) => {
    setForgetData({
      ...forgetData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
  e.preventDefault();
  handleForgotPassword(forgetData)
    .then((response) => {
      if (response.success) {
        setForgetSuccess([response.data.message])
        setForgetError([])
      }
      else {
        handleUpdateError(response.error);
      }
    })
    .catch(handleUpdateError);
};

  const handleUpdateError = (error) => {
    const errorMessage = error?.response?.status === 400 ? error.response.data.message : "An error occurred";
    setForgetError([errorMessage]);
    setForgetSuccess([]);
  };

  const errorMsg = forgetError.map((item, index) => (
    <h5 key={index} className="error_msg">
      {item}
    </h5>
  ));
  const successMsg = forgetSuccess.map((item, index) => {
    return (
      <h5 key={index} className="success_msg">
        {item}
      </h5>
    );
  });
  return (
    <div className="forget-circle">
      <div className="forget">
        <div className="left-forget">
          <div className="hero-animation">
            <img src="/hero-animation.svg" className="hero-animation" />
          </div>

          <div className="grow-your-business">
            <img src={growYourBusiness} />
          </div>
        </div>

        <div className="right-forget">
          <div className="right-forget-wrapper">
            <div className="d-flex justify-content-center mb-3 small-screen-logo">
              {/* <a href="/" className="nav-link">
                <img src={Logo} alt="" className="logo-img" />
                <span className="site_title">KinReviews</span>
              </a> */}
            </div>
            <div className="forget-form">
              <form onSubmit={handleSubmit}>
                <h6>I forgot my password</h6>
                {errorMsg && <div className="mb-3 errorm">{errorMsg}</div>}
                {successMsg && <div className="mb-3 successm">{successMsg}</div>}
                <div className="mb-3">
                  <div className="mb-1">Enter your Email to receive a reset link</div>
                  <div className="d-flex">
                    <input
                      required
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={forgetData.email}
                      onChange={handleDataChange}
                    />
                  </div>
                </div>
                <div>
                </div>
                <div>
                  <button className="submit-btn" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;

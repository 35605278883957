import React, { useEffect, useState } from 'react';
const Success = (props) => {

    const {isLoggedIn} = props;

    const [dialogHeight, setDialogHeight] = useState(100);

    useEffect (() => {

        const setContentHeight = () => {
            let navbarHeight = document.querySelector('.header_section');
            let footerHeight = document.querySelector('.footer-main');

            console.log(navbarHeight)

            navbarHeight = navbarHeight ? navbarHeight.offsetHeight : 0;
            footerHeight = footerHeight ? footerHeight.offsetHeight : 0;

            let windowHeight = window.innerHeight;
            setDialogHeight(windowHeight - (navbarHeight + footerHeight));
        }

        setContentHeight();

        window.addEventListener('resize', setContentHeight());
    })

    return (
        <>
            <div 
                style={{minHeight : dialogHeight+'px'}}
                className='pt-5'
            >   
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img src='/success-standard-solid.svg'></img>

                    </div>
                    <h1 className='text-center font-weight-bold mt-3'>PAYMENT SUCCESSFULL</h1>
                    {isLoggedIn && 
                        <>
                            <h4 className='text-center'>Your Order Id: #12334</h4>
                            <p className='text-center'>
                                Track your order From <a href='/mes-commandes'>Here</a>
                            </p>
                        </>
                    }
                    {!isLoggedIn && (
                        <>
                            <p className='text-center'>
                            Thank you for your order. We have received your request and will process it shortly.
                            </p>
                            <p className='text-center'>
                            An email with additional information about your order will be sent to you shortly.
                            </p>
                            <p className='text-center'>
                            If you have any questions or need further assistance, please don't hesitate to <a href='/contact'>contact us</a>.
                            </p>
                            <p className='text-center'>
                            Order another <a href='/#ordering_section'>service</a>.
                            </p>
                        </>
)}
                </div>
                
            </div>
        </>
    );
}

export default Success;